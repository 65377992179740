import "./style/aboutMeStyle.css";
import HeaderNav from "./HeaderNav";
import FirstSection from "./FirstSection.jsx";
import Resume from "./Resume";
//import Skills from "./Skills";
import Career from "./Career";
import KnowMeSoc from "./KnowMeSoc";

function StartPage() {
  /**
   * <Skills />
   */
  return (
    <div className="main-bod">
      <HeaderNav />

      <FirstSection />

      <Resume />

      <Career />

      <KnowMeSoc />

      <div className="footer">
        <div className="footer">
          <div className="container-4 w-container">
            <h4 className="heading-3">
              <br />
              Inspiration from{" "}
              <a
                href="https://www.ojieame.design/"
                target="_blank"
                rel="noreferrer"
              >
                Ojieame
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartPage;
