function Resume() {
  return (
    <div id="GRAPHIC" className="section mm23 wf-section">
      <div className="container-2 bvc w-container">
        <LeftSideTitle />
        <RightSideText />
      </div>
    </div>
  );
}

export default Resume;

const LeftSideTitle = () => {
  return (
    <div className="left-plenty-text">
      <div className="skilld">
        <strong>skills</strong>
      </div>
      <h1>Computer Engineer & Full-Stack Developer</h1>
    </div>
  );
};

const RightSideText = () => {
  return (
    <div className="right-getr">
      <p className="paragraph-2 downsa">
        I have over 3 years of professional experience in software development,
        application engineering, and software project management. I have
        extensive experience in developing, testing, and deploying software
        applications for a variety of platforms and industries.
        <br />
        My expertise includes working with C# and .NET, software engineering
        principles, object-oriented design, software architecture, database
        design, and software development life cycle. I have experience working
        with web and mobile technologies, such as HTML5, CSS3, React, and
        Node.js.
      </p>
    </div>
  );
};
