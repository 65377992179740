/***
 * Remove the comment in order to apply the  css code on the design.
 */
//import "./App.css";

//import TheApp from "./components/oldDesign/TheApp";
import StartPage from "./components/activeDesign/StartPage";

function App() {
  /*< AboutMe/>  <TheApp />*/
  return (
    <div className="App">
      <StartPage />
    </div>
  );
}

export default App;
