import M from "materialize-css";
import { useEffect } from "react";

function HeaderNav() {
  useEffect(() => {
    let dropdowns = document.querySelectorAll(".sidenav");
    M.Sidenav.init(dropdowns);
  }, []);

  /**
     * The logo icon in the left side of the header.
     * <img
          className="image-2"
          src="https://uploads-ssl.webflow.com/5b32655069229148933e1636/5b33702221d4b3baba40e16a_Asset%203.svg"
          alt=""
          width="50"
        ></img>
     */
  return (
    <div>
      <div className="nav">
        <SideNavTrigger />

        <div className="nav-contain w-container">
          <HeaderNavList />
        </div>
      </div>
      <SideNaveList />
    </div>
  );
}

export default HeaderNav;

const SideNavTrigger = () => {
  return (
    <a
      href="/"
      data-target="mobile-demo"
      className="sidenav-trigger"
      onMouseEnter={(e) => {
        const inst = M.Sidenav.getInstance(e.target);
        inst && inst.open();
      }}
      onMouseLeave={(e) => {
        const inst = M.Sidenav.getInstance(e.target);
        inst && inst.close();
      }}
    >
      <i className="material-icons">menu</i>
    </a>
  );
};

const HeaderNavList = () => {
  return (
    <ul
      id="nav-mobile"
      className="right hide-on-med-and-down"
      style={{ listStyle: "none" }}
    >
      <li style={{ float: "left" }}>
        <a href="#ABOUT_ME" style={{ padding: "0 40px" }}>
          About me
        </a>
      </li>
      <li style={{ float: "left" }}>
        <a href="#GRAPHIC" style={{ padding: "0 40px" }}>
          Skills
        </a>
      </li>
      <li style={{ float: "left" }}>
        <a href="#cv" style={{ padding: "0 40px" }}>
          Courses
        </a>
      </li>
    </ul>
  );
};

const SideNaveList = () => {
  return (
    <ul className="sidenav" id="mobile-demo" style={{ top: "60px" }}>
      <li>
        <a href="/">About me</a>
      </li>
      <li>
        <a href="/">Skills</a>
      </li>

      <li>
        <a
          href="https://lovely-ganache-495aec.netlify.app/"
          target="_blank"
          rel="noreferrer"
        >
          Game created by me!
        </a>
      </li>
      <li>
        <a
          href="https://github.com/AhmadAddee"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ maxWidth: "50px" }}
            src="https://cdn-icons-png.flaticon.com/512/25/25231.png"
            alt="github_icon"
          ></img>
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/ahmad-al-khateeb-583363242"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ maxWidth: "50px" }}
            src="https://www.edigitalagency.com.au/wp-content/uploads/Linkedin-logo-png.png"
            alt="linkedin_icon"
          ></img>
        </a>
      </li>
      <li>
        <a
          href="https://www.kth.se/utbildning/hogskoleingenjor/datateknik-flemingsberg/"
          target="_blank"
          rel="noreferrer"
          style={{ display: "inline-grid" }}
        >
          <img
            style={{ maxWidth: "50px" }}
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjHcW1fFFpiqS7g-SzxlBqydCVK5cDi6XdXQ&usqp=CAU"
            alt="kth_icon"
          ></img>
        </a>
      </li>
    </ul>
  );
};

// ul: .right{ float: right !important }
// li: nav ul li { float: left } **** (li)
// a: nav ul a { padding: 0 15px}  ******* (a)
