function FirstSection() {
  return (
    <div className="main-section heavy0heavy wf-section">
      <div className="container w-container">
        <LeftSidePresentation />
        <RightSidePhote />
      </div>
    </div>
  );
}

export default FirstSection;

const LeftSidePresentation = () => {
  return (
    <div className="left-text-box">
      <h1>Ahmad Al Khateeb</h1>

      <p className="paragraph-2 qll">
        A passionate software developer who is devoted to constructing software
        solutions that aim to make the world better.
      </p>

      <h1 className="heading-2 work-title">
        Software <br /> Engineer
      </h1>

      <a className="sroll-button w-button" href="#GRAPHIC">
        SCROLL FOR MORE
      </a>
    </div>
  );
};

const RightSidePhote = () => {
  return (
    <div className="right-image-box">
      <div className="image-containers"></div>
    </div>
  );
};
