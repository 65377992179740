import { useEffect, useState } from "react";

function KnowMeSoc() {
  const [choosenImage, setChoosenImage] = useState(
    "http://webtoolfeed.files.wordpress.com/2012/10/streets-11.jpg"
  );

  var [imageUrls] = useState([
    "https://i.pinimg.com/originals/84/21/9a/84219a1cab5bcd8b1de509a3624c0ca2.jpg",
    "http://webtoolfeed.files.wordpress.com/2012/10/streets-11.jpg",
    "http://wallpaperstock.net/facebook-cover%2C-love%2C-lovely%2C-nice%2C-cool%2C-touch%2C-beauty_wallpapers_57060_1680x1050.jpg",
    "http://webtoolfeed.files.wordpress.com/2012/10/lamps-macro-l1.jpg",
    "https://media.istockphoto.com/id/1151630643/photo/red-megaphone-and-silver-hashtag-symbols-in-front-of-gray-wall.jpg?b=1&s=170667a&w=0&k=20&c=Ir3sn0r5BJiudYovpl61Su893L36pKv2MgYwt0cVnRY=",
  ]);

  useEffect(() => {
    var randomIndex = Math.floor(Math.random() * imageUrls.length);
    var img = imageUrls[randomIndex];
    setChoosenImage(img);
  }, [imageUrls]);

  return (
    <div className="lokjj wf-section" id="ABOUT_ME">
      <div className="container-2 bvc w-container">
        <div className="left-plenty-text">
          <div className="skilld">
            <strong>ABOUT ME</strong>
          </div>
          <h1 className="llk">Developing software solution for better world</h1>
          <a href="/" className="sroll-button bff w-button">
            follow me linkedin
          </a>
        </div>
        <div className="right-getr">
          <p className="paragraph-2 downsa">
            I have a degree in computer science and have been programming for
            over three years. My skills include creating web applications,
            mobile applications and software solutions using different
            programming languages. Additionally, I have experience working with
            databases such as MySQL and Oracle. I'm also familiar with software
            development methodologies such as Agile and Waterfall and have
            experience working in a team environment. I'm eager to learn and
            improve my skills and am confident that I will be a valuable asset
            to any software engineering team.
          </p>
        </div>
      </div>
      <div
        data-poster-url="https://uploads-ssl.webflow.com/5b32655069229148933e1636/5b33c8c5e43d9d10fd5aa416_mnnn-poster-00001.jpg"
        data-video-urls="https://uploads-ssl.webflow.com/5b32655069229148933e1636/5b33c8c5e43d9d10fd5aa416_mnnn-transcode.mp4,https://uploads-ssl.webflow.com/5b32655069229148933e1636/5b33c8c5e43d9d10fd5aa416_mnnn-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        data-beta-bgvideo-upgrade="false"
        className="bbackground-vid w-background-video w-background-video-atom"
      >
        <video
          id="a8aac476-06a2-9207-1d10-01de317ca017-video"
          style={{
            backgroundImage: `url("${choosenImage}")`,
          }}
        ></video>
      </div>
      <div className="div-block-2 shade w-clearfix">
        <div className="black-shade"></div>
      </div>
    </div>
  );
}

export default KnowMeSoc;
