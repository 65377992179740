import MyCV from "../../assets/Ahmad_Al_khateeb_CV.pdf";
import MyCvEng from "../../assets/English_CV_ACTIVE.pdf";

function Career() {
  return (
    <div className="big-one careers wf-section" id="cv">
      <div className="bvqq w-container">
        <h1 className="heading-2 careers">Career</h1>
        <div>
          <a
            className="sroll-button ntreq gee bgr w-button"
            href={MyCV}
            target="_blank"
            rel="noreferrer"
            style={{
              backgroundImage:
                'url("https://www.norden.org/sites/default/files/images/Svensk%2520flag693684.jpeg")',
            }}
          >
            Get cv
          </a>
          <a
            className="sroll-button ntreq gee bgr w-button"
            href={MyCvEng}
            target="_blank"
            rel="noreferrer"
            style={{
              backgroundImage:
                'url("https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png")',
            }}
          >
            Get cv
          </a>
        </div>
      </div>
      <div
        className="container-3 lpo w-container"
        data-w-id="6bb00cfa-3220-c310-9744-60028e68be7a"
      >
        <div className="career-column">
          <h4 className="company">Novare Potential</h4>
          <h1 className="position">Teaching Assistant</h1>
          <h1 className="position dates">2022, Juni - September</h1>
        </div>
      </div>
    </div>
  );
}

export default Career;
